import { ADDING, ADDING_SUCCESS, ADDING_ERROR } from './actions'

export const initialState = {
  status: null,
  response: null,
}

const reducer = (state = initialState, { type, response } = {}) => {
  switch (type) {
    case ADDING:
      return { ...initialState, status: ADDING };
    case ADDING_SUCCESS:
      return { ...state, status: ADDING_SUCCESS, response };
    case ADDING_ERROR:
      return { ...state, status: ADDING_ERROR, response };
    default:
      return state;
  }
}

export default reducer
