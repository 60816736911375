import {
  LOGIN_USERNAME,
  LOGIN_PASSWORD,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  SIGNUP_NAME,
  SIGNUP_USERNAME,
  SIGNUP_PASSWORD,
  SIGNUP_REQUEST,
  SIGNUP_ERROR,
  LOGOUT_SUCCESS,
  UPDATE_USER
} from './actions'

const initialState = {
  login: {
    email: '',
    password: '',
    requesting: false,
    successful: false,
    messages: [],
    errors: []
  },
  signup: {
    name: '',
    email: '',
    password: '',
    requesting: false,
    successful: false,
    messages: [],
    errors: []
  },
  token: false
}

const reducer = (state = initialState, { type, payload = null }) => {
  switch (type) {
    case LOGIN_USERNAME: {
      return {
        ...state,
        login: {
          ...state.login,
          errors: [],
          email: payload
        }
      }
    }
    case LOGIN_PASSWORD: {
      return {
        ...state,
        login: {
          ...state.login,
          errors: [],
          password: payload
        }
      }
    }
    // Set the requesting flag and append a message to be shown
    case LOGIN_REQUEST: {
      return {
        ...state,
        login: {
          ...state.login,
          requesting: true,
          successful: false,
          messages: [{ body: 'Logging in...', time: new Date() }],
          errors: []
        }
      }
    }

    // Reset the login state.
    case LOGIN_SUCCESS: {
      return {
        login: {
          email: '',
          password: '',
          errors: [],
          messages: [],
          requesting: false,
          successful: true
        },
        signup: {
          name: '',
          email: '',
          password: '',
          requesting: false,
          successful: false,
          messages: [],
          errors: []
        },
        token: payload
      }
    }

    // Append the error returned from our API
    // Set the success and requesting flags to false
    case LOGIN_ERROR: {
      return {
        ...state,
        login: {
          ...state.login,
          errors: state.login.errors.concat([
            {
              type: payload,
              time: new Date()
            }
          ]),
          messages: [],
          requesting: false,
          successful: false,
        }
      }
    }

    case SIGNUP_NAME: {
      return {
        ...state,
        signup: {
          ...state.signup,
          errors: [],
          name: payload
        }
      }
    }
    case SIGNUP_USERNAME: {
      return {
        ...state,
        signup: {
          ...state.signup,
          errors: [],
          email: payload
        }
      }
    }
    case SIGNUP_PASSWORD: {
      return {
        ...state,
        signup: {
          ...state.signup,
          errors: [],
          password: payload
        }
      }
    }
    case SIGNUP_ERROR: {
      return {
        ...state,
        signup: {
          ...state.signup,
          errors: state.signup.errors.concat([
            {
              type: payload,
              time: new Date()
            }
          ]),
          messages: [],
          requesting: false,
          successful: false,
        }
      }
    }
    // Set the requesting flag and append a message to be shown
    case SIGNUP_REQUEST: {
      return {
        ...state,
        signup: {
          ...state.signup,
          requesting: true,
          successful: false,
          messages: [{ body: 'Signing up...', time: new Date() }],
          errors: []
        }
      }
    }

    case UPDATE_USER: {
      return state
    }

    // Reset the login state.
    case LOGOUT_SUCCESS: {
      return initialState
    }

    default:
      return state
  }
}

export default reducer
