import { Main as _Main } from 'style'
import styled from 'styled-components'

export const Main = styled(_Main)`
  margin: 0 auto;
  @media (min-width: 767px) {
    display: flex;
  }
  @media (min-width: 1000px) {
    max-width: 800px;
  }
`

export const Div = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 60px;
  @media (min-width: 767px) {
    width: 50%;
  }
  p {
    font-weight: 700;
    margin-bottom: 20px;
  }
  > img { /* Loading Div */
    margin-top: 30px;
  }
`
