import { useReducer, useCallback } from 'react'
import { baseRequest } from 'utils'
import reducer, { initialState } from './reducer'

export const FETCHING = `FETCHING`
export const SUCCESS = `SUCCESS`
export const ERROR = `ERROR`

export const useApiRequest = (endpoint, { verb = 'get', params = {} } = {}) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const makeRequest = useCallback(async () => {
    dispatch(fetching())
    try {
      const response = await baseRequest[verb](endpoint, params)
      setTimeout(() => dispatch(success(response)), 2000)
    } catch (e) {
      setTimeout(() => dispatch(error(e)), 2000)
    }
  }, [endpoint, verb, params])

  return [state, makeRequest]
}

const fetching = () => ({ type: FETCHING })
const success = response => ({ type: SUCCESS, response })
const error = response => ({ type: ERROR, response })
