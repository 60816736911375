import axios from 'axios'

export const baseRequest = axios.create({ baseURL: process.env.REACT_APP_END_POINT })

export const getAuthorisationHeaders = token =>  ({ headers: { authorization: `token ${token}` }})

export function validateEmail(email) {
  const re = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  return re.test(email)
}

export function validateName(name) {
  const re = /^[a-zA-Z\-’]+( [a-zA-Z\-’]+)+$/;
  return re.test(name)
}

export function validatePassword(password) {
  const re = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");
  return re.test(password)
}
