import { useState } from 'react'
import { connect } from 'react-redux'
import { getAuthorisationHeaders } from 'utils'
import { ADDING, ADDING_ERROR, AddRequest } from './actions'
import loading from 'img/loading.svg'

const Add = ({ auth: { token }}) => {
  const [name, setName] = useState('')
  const [svg, setSvg] = useState('')
  const [{ status }, addCreate] = AddRequest(`http://localhost:4000/admin/create/add`,
    {
      verb: 'post',
      params: {
        data: { name, svg },
      },
      headers: { ...getAuthorisationHeaders(token) }
    }
  )

  return (
    <div>
      <h3>{'Add Create +'}</h3>
      {status === ADDING
        ? <img src={loading} alt={''} />
        : <>
            <div>
              <input type='text' placeholder={'Name'} value={name} onChange={e => setName(e.target.value)} />
            </div>
            <div>
              <textarea value={svg} placeholder={'SVG'} onChange={e => setSvg(e.target.value)} />
            </div>
            <div>
              <button onClick={() => addCreate()}>{'Add'}</button>
            </div>
          </>
      }
      {status === ADDING_ERROR &&
        <p>{'There was a problem adding the data.'}</p>
      }
    </div>
  )
}

const connected = connect(({ auth }) => ({ auth }), null)(Add)
export default connected
