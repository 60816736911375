import styled from 'styled-components'

export const Div = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 10000;
  top: 0;
  background-color: rgba(255,255,255,0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`
