import * as S from './Stylist.styles'

function Stylist() {
  return (
    <S.Main>
      <p>-- Stylist Page --</p>
    </S.Main>
  )
}

export default Stylist
