import styled from 'styled-components'
import { Main as _Main } from 'style'

export const Main = styled(_Main)`
  h2 {
    margin-bottom: 40px;
  }
  > div {
    &:first-of-type {
      margin-bottom: 40px;
    }
  }
`
