import { useReducer, useCallback } from 'react'
import { baseRequest } from 'utils'
import reducer, { initialState } from './reducer'

export const ADDING = `ADDING`
export const ADDING_SUCCESS = `ADDING_SUCCESS`
export const ADDING_ERROR = `ADDING_ERROR`

export const AddRequest = (endpoint, { verb = 'post', params = {}, headers={} } = {}) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const makeRequest = useCallback(async () => {
    dispatch(adding())
    try {
      const response = await baseRequest[verb](endpoint, params, headers)
      setTimeout(() => dispatch(adding_success(response)), 2000)
    } catch (e) {
      setTimeout(() => dispatch(adding_error(e)), 2000)
    }
  }, [endpoint, verb, params, headers])

  return [state, makeRequest]
}

const adding = () => ({ type: ADDING })
const adding_success = response => ({ type: ADDING_SUCCESS, response })
const adding_error = response => ({ type: ADDING_ERROR, response })
