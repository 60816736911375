import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { logoutRequest, loginFromTokenRequest } from 'App/auth/actions'
import HeaderLinks from './HeaderLinks'
// import CreateMenu from 'components/CreateMenu'
import the from 'img/THELogo.png'
import * as S from './Header.style'

function Header(props) {
  const { auth: { login }, logoutRequest, loginFromTokenRequest } = props
  const [attemptedLogin, setAttemptedLogin] = useState(false)
  useEffect(() => {
    if (!login.successful && !attemptedLogin) {
      loginFromTokenRequest()
      setAttemptedLogin(true)
    }
  }, [login, attemptedLogin, loginFromTokenRequest])
  return (
    <S.Header>
      <p><Link to={'/create/tops'}>{'Create Tops (beta)'}</Link></p>
      {/* <CreateMenu { ...props } /> */}
      <p><HeaderLinks loggedIn={login.successful} logout={() => logoutRequest()} /></p>
      <h1><Link to="/"><img src={the} alt={''} />{'Remake'}</Link></h1>
    </S.Header>
  )
}

const mapStateToProps = ({ auth }) => ({ auth })
const connected = connect(mapStateToProps, { logoutRequest, loginFromTokenRequest })
export default connected(Header)
