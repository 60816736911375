import React, { Fragment, useState } from 'react'
import { steps } from './steps'
import * as S from './CreateSteps.styles'

const StepsMenu = ({ data, setBodice, setSleeve, setBack, setSwatch, setNeckline, neckline, bodice }) => {
  const [currentStep, setCurrentStep] = useState(0)
  return (
    <S.Div>
      {steps.map((step, i) =>
        <Fragment key={i}>
          <S.SelectableDiv onClick={() => setCurrentStep(i)} currentStep={currentStep === i} step={i} style={{ zIndex: i }}>
            <h3 dangerouslySetInnerHTML={{ __html: step }} />
            <div dangerouslySetInnerHTML={{ __html: currentStep === i ? '&#9661;' : '&#9665;'}} />
          </S.SelectableDiv>
          <S.ExpandableDiv open={currentStep === i} style={{ zIndex: i }}>
              {i === 0 && <Step options={data.bodices} stepType={'bodices'} handleClick={setBodice} />}
              {i === 1 && bodice > -1 && <BackStep options={data.necklines} stepType={'necklines'} handleClick={i => {
                setNeckline(i)
                setBack(-1)
              }} permitted={data.bodices[bodice].permitted}  />}
              {i === 2 && neckline > -1 && <BackStep options={data.backs} permitted={data.necklines[neckline].permitted} stepType={'backs'} handleClick={setBack} />}
              {i === 3 && <Step options={data.sleeves} stepType={'sleeves'} handleClick={setSleeve} />}
              {i === 4 && <Step options={data.swatches} stepType={'swatches'} handleClick={setSwatch} />}
          </S.ExpandableDiv>
        </Fragment>
      )}
    </S.Div>
  )
}

export default StepsMenu

const BackStep = ({ options, handleClick,  stepType, permitted }) =>
  options.map(({ name, imgSrc, price }, i) =>
    permitted.includes(name) &&
      <S.StepDiv key={i} className={stepType} onClick={() => handleClick(i)}>
        <div style={{ backgroundImage: `url(${imgSrc})`}}>
          <img src={imgSrc} alt={name} />
        </div>
        <p><b>{name}</b><br />£{price}</p>
      </S.StepDiv>
  )

const Step = ({ options, handleClick, stepType }) =>
  options.map(({ name, imgSrc, price }, i) =>
    <S.StepDiv key={i} className={stepType} onClick={() => handleClick(i)}>
      <div style={{ backgroundImage: `url(${imgSrc})`}}>
        <img src={imgSrc} alt={name} />
      </div>
      <p><b>{name}</b><br />£{price}</p>
    </S.StepDiv>
  )
