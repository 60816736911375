export const links = [
  {
    title: 'About',
    url: '/about'
  },
  /* {
    title: 'Collection',
    url: '/collection'
  }, */
  {
    title: 'Create',
    url: '/create/tops'
  },
  /* {
    title: 'Remake',
    url: '/remake'
  },
  {
    title: 'Personal Stylist',
    url: '/stylist'
  } */
]
