import { useState } from 'react'
import * as S from './Edit.styles'

const Edit = ({ editing: { _id, name, svg, active }, setEditing }) => {
  const [edit, setEdit] = useState({ name, svg, active })
  return (
    <S.Div>
      <label htmlFor={'name'}>{'Name:'}</label>
      <input type={'text'} value={edit.name} name={'name'} onChange={e => setEdit({...edit, name: e.target.value })} />
      <label htmlFor={'svg'}>{'SVG:'}</label>
      <textarea value={edit.svg} name={'svg'} onChange={e => setEdit({...edit, name: e.target.value })} />
      <label htmlFor={'active'}>{'Active'}</label>
      <input type={'checkbox'} name={'active'} checked={edit.active === 1} onChange={e => setEdit({...edit, active: edit.active === 0 ? 1 : 0 })} />
      <div>
        <button onClick={() => {
          document.body.style.overflow = 'auto'
          setEditing(false)
        }}>{'Cancel / Close'}</button>
        <button onClick={() => {
          document.body.style.overflow = 'auto'
          setEditing(false)
        }}>{'Save'}</button>
      </div>
    </S.Div>
  )
}

export default Edit
