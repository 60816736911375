import styled from 'styled-components'

export const Div = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 143px);
  overflow: auto;
  padding-right: 20px;
`

export const SelectableDiv = styled.div`
  display: flex;
  width: 100%;
  z-index: ${props => props.step};
  &:hover {
    cursor: pointer;
    opacity: 0.75;
  }
  > * {
    display: flex;
    align-items: center;
  }
  > h3 {
    text-align: left;
    padding: 12px 0 8px;
    margin-right: 1%;
    flex: 1;
    border-bottom: 1px solid #333;
    font-weight: ${props => props.currentStep ? '700' : '400'};
  }
`

export const ExpandableDiv = styled.div`
  width: 100%;
  opacity: ${props => props.open ? 1 : 0};
  height: ${props => props.open ? 'auto' : 0};
  padding: ${props => props.open ? '20px 0' : 0};
  display: ${props => props.open ? 'flex' : 'none'};
  transition: .25s ease-in-out;
  flex-direction: flex-start;
  flex-wrap: wrap;
  font-family: lato, sans-serif;
`

export const StepDiv = styled.div`
  width: 20%;
  margin-bottom: 12px;
  > div {
    width: 100%;
    background-size: 150% auto;
    background-position: 50% 0;
    background-repeat: no-repeat;
    > img {
      width: 80%;
      cursor: pointer;
      opacity: 0;
    }
  }
  &.swatches {
    width: 16.6%;
  }
  &.necklines {
    > div {
      background-size: 200% auto;
      background-position: 50% 0;
    }
  }
  &.sleeves {
    > div {
      background-size: 100% auto;
      background-position: 0 0;
    }
  }
  &.swatches {
    > div {
      background-size: 80% auto;
      background-position: 20% 0;
    }
  }
  p {
    font-size: 12px;
    line-height: 15px;
    color: #333;
    font-weight: 200;
  }
`
