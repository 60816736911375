import { Link } from 'react-router-dom'

function HeaderLinks({ loggedIn, logout }) {
  return loggedIn
    ? <>
        {/* <Link to="/admin">{'Admin'}</Link>{' - '} */}
        <Link to="/profile">{'Profile'}</Link>{' - '}
        <span onClick={logout}>{'Log Out'}</span>
      </>
    : <Link to="/login">{'Login / Signup'}</Link>
}

export default HeaderLinks
