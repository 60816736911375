import React, { useState, useEffect } from 'react'
import axios from 'axios'
import * as S from './Users.styles'

const Users = () => {
  const [users, setUsers] = useState(false)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  useEffect(() => {
    if (!users) {
      axios.get('http://localhost:4000/users').then(r => {
        console.log(r)
        setUsers(r.data)
      }).catch(err => console.log(err))
    }
  }, [users])
  const addUser = () => {
    axios.post('http://localhost:4000/users/user', { user: { name: name, email: email, country: 'uk' }, action: 'add' }).then(() =>
      setUsers(false)
    ).catch(err => console.log(err))
  }
  return (
    <S.Main>
      <h2>{'Users'}</h2>
      <div>
        {!!users && users.map((user, key) =>
          <p {...{ key }}>{`Name: ${user.name} - ${user.email}`}</p>
        )}
      </div>
      <div>
        <input type='text' value={name} placeholder={'Name'} onChange={e => setName(e.target.value)} />
        <input type='email' value={email} placeholder={'Email'} onChange={e => setEmail(e.target.value)} />
        <button onClick={addUser}>{'Add User'}</button>
      </div>
    </S.Main>
  )
}

export default Users
