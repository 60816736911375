import styled from 'styled-components'

export const Div = styled.div`
  > div {
    > div {
      display: flex;
      width: 100%;
      justify-content: space-around;
      > div {
        overflow: hidden;
        text-align: left;
        flex: 1;
        &:first-of-type {
          max-width: 100px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding-right: 16px;
        }
      }
    }
  }

`
