import styled from 'styled-components'
import { Main as _Main } from 'style'

export const Main = styled(_Main)`
  display: flex;
  align-items: center;
  flex-direction: column;
  ul {
    display: flex;
    justify-content: center;
    transition: .25s ease-in-out;
    width: 100%;
    opacity: 0;
    margin-bottom: 30px;
    @media (min-width: 800px) {
      opacity: 1;
    }
    li {
      text-transform: uppercase;
      font-family: lato, sans-serif;
      font-size: 15px;
      font-weight: 400;
      a {
        color: #333;
        text-decoration: none;
        &:hover {
          opacity: 0.75;
        }
      }
      margin-right: 30px;
      &:last-of-type {
        margin-right: 0;
      }
    }
  }
`
