import styled from 'styled-components'
/* opacity: 0 is commentedout from line 7 */
export const Ul = styled.ul`
  display: flex;
  justify-content: center;
  transition: .25s ease-in-out;

  margin-bottom: 40px;
  @media (min-width: 800px) {
    opacity: 1;
  }
  li {
    text-transform: uppercase;
    font-family: lato, sans-serif;
    font-size: 15px;
    font-weight: 400;
    a {
      color: #333;
      text-decoration: none;
      &:hover {
        opacity: 0.75;
      }
    }
    margin-right: 30px;
    &:last-of-type {
      margin-right: 0;
    }
  }
`
