import * as S from './About.styles'
import pic1 from 'img/general/AboutUs-slides-APP.jpg'
import pic2 from 'img/general/AboutUs-slides-ICONS.jpg'
import pic3 from 'img/general/AboutUs-slides-SKETCH.jpg'
import pic4 from 'img/general/AboutUs-slides-TRYON.jpg'

function About() {
  return (
    <S.Main>
      <section>
        <h2>{'“Create your own visual style... let it be unique for yourself and yet identifiable for others.”'} <span>{'Orson Welles'}</span></h2>
      </section>
      <section>
        <h2>{'Fresh perspectives on original classics'}</h2>
        <p>{'Fusing the elegance of traditional tailoring principles with emerging technology to create a wardrobe that reflects your unique style and body - perfectly. Creating pieces designed by you, constructed locally in the UK, and created to endure beyond fashion trends, The Remake seeks to reduce the potential for returns and promote <slow fashion>ideals in a bid to support a more <sustainable fashion> future.'}</p>
      </section>
      <section>
        <h2 style={{ textDecoration: 'underline' }}>{'How it works'}</h2>
        <h2>{'Customer driven design'}</h2>
        <p>{'Choose your shape, fabric, and design features to create pieces that reflect your own personal style. Use Sketch Mode to try different ideas and illustrate your final design..'}</p>
        <img src={pic3} alt={'How it works'} style={{ marginTop: '10vh'}} />
      </section>
      <section>
        {/* <p>{'Take your measurements quickly and easily with the MOBILE TAILOR APP - just two photos, from the front and side.'}</p> */}
      </section>
      <section>
        <h2>{'Made to measure'}</h2>
        <p>{'Quality and fit are at the core of what we do. Fed up with buying clothes that do not quite fit, we aim to make bespoke tailoring more accessible and produce clothes that fit our customers perfectly. The benefits of this go beyond feeling confident in our clothing; customers will find dressing is simpli- fied and wardrobes streamlined - less clothing pro- duced reduces personal clutter and waste destined for landfill.'}</p>
        <img src={pic1} alt={'How it works'} style={{ marginTop: '10vh'}}/>
      </section>
      <section>
        <h2>{'Virtual try-on'}</h2>
        <p>{'Submit your design to be drawn up in 3D modelling for a true depiction of your garment'}</p>
        <img src={pic4} alt={'Made to measure'} style={{ marginTop: '10vh'}} />
      </section>
      <section>
        <h2>{'Personal service'}</h2>
        <p>{'Take delivery and indulge in the pleasure of wearing a unique piece of clothing designed by you, made just for you.'}</p>
          <img src={pic2} alt={'Personal service'} style={{ marginTop: '10vh'}}/>
      </section>
      <section>
        <h2>{'Local production'}</h2>
        <p>{'Be reassured that your piece has been made in the UK supporting small businesses and using sustainable production methods where possible. Our aim is to continually evolve regarding sustainability, keeping abreast of developments and new technologies that can assist us in this pursuit to create a slow fashion revolution.'}</p>
      </section>
    </S.Main>
  )
}

export default About
