import styled from 'styled-components'
import { Main as _Main } from 'style'
import dummy from 'img/paperdoll/masterdummy.svg'

export const Main = styled(_Main)`
  display: flex;
  > div {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
`

export const Tailor = styled.div`
  width: 45%;
  margin-right: 5%;
  height: calc(100% - 143px);
  background-image: url(${dummy});
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: 0 center;
  position: relative;
  height: 500px;
  border-right: dotted 1px #333;
  > div {
    position: absolute;
    &.swatch {
      left: 0px;
      bottom: 30px;
      img {
        width: 130px;
      }
    }
    &.backSection {
      right: 40px;
      top: 30px;
      width: 160px;
      height: 230px;
      background-repeat: no-repeat;
      background-size: 200% auto;
      background-position: 50% 0;
      border: 1px solid #666;
      &:before {
        content: 'Reverse view:';
        position: absolute;
        font-size: 14px;
        top: -20px;
        font-weight: bold;
      }
      img {
        width: 200%;
        margin-left: -50%;
      }
    }
    &.price {
      bottom: 0;
      right: 32px;
      text-align: right;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      > b {
        margin: 8px 0;
        text-align: right;
      }
      p {
        margin-bottom: 8px;
        font-size: 14px;
        animation: fadeIn ease .5s;
      }
      button {
        background-color:#000;
        color:#FFF;
        font-family: lato;
        width: 180px;
        margin-top: 10px;
        &:disabled {
          opacity: 0.5;
        }
      }
    }
    &.refs {
      display: flex;
      bottom: -160px;
      width:100%;
      left:-10px;
      img {
        max-width: 80px;
        margin-right: 10px;
      }
      p {
        font-size: 12px;
        text-align: left;
      }
      &:after {
        content: 'Pattern reference images:';
        font-weight: 700;
        position: absolute;
        margin-top: -30px;
      }
    }
  }
  > img {
    position: absolute;
    height: 100%;
    animation: fadeIn ease .5s;
  }
`


export const Order = styled.div`
  width: 60%;
  margin-left: 20%;
  height: calc(100% - 88px);
  background-image: url(${dummy});
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: 0 center;
  position: relative;
  height: 500px;
  > div {
    position: absolute;
    &.price {
      bottom: 0;
      right: 0;
      text-align: right;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      > b {
        margin: 8px 0;
        text-align: right;
      }
      p {
        margin-bottom: 8px;
        font-size: 14px;
        animation: fadeIn ease .5s;
      }
      button {
        background-color:#000;
        color:#FFF;
        font-family: lato;
        width: 180px;
        margin-top: 10px;
        &:disabled {
          opacity: 0.5;
        }
      }
    }
    &.success {
      top: 0;
      right: 0;
      text-align: right;
      width: 320px;
    }
  }
  > img {
    position: absolute;
    height: 100%;
    animation: fadeIn ease .5s;
  }

`
