import { validateEmail, validateName, validatePassword } from 'utils'

export const LOGIN_USERNAME = 'LOGIN_USERNAME'
export const LOGIN_PASSWORD = 'LOGIN_PASSWORD'
export const SIGNUP_USERNAME = 'SIGNUP_USERNAME'
export const SIGNUP_PASSWORD = 'SIGNUP_PASSWORD'
export const SIGNUP_NAME = 'SIGNUP_NAME'
export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const SIGNUP_REQUEST = 'SIGNUP_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const UPDATE_USER = 'UPDATE_USER'
export const LOGIN_ERROR = 'LOGIN_ERROR'
export const SIGNUP_ERROR = 'SIGNUP_ERROR'
export const LOGIN_EXISTING = 'LOGIN_EXISTING'
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGIN_FROM_TOKEN_REQUEST = 'LOGIN_FROM_TOKEN_REQUEST'


function checkLoginDetails(email, password) {
  if (!validateEmail(email) || email === '') {
    return 'Please use a valid email address'
  }
  if (!validatePassword(password)) {
    return 'Please leave a valid password'
  }
  return 'valid'
}

function checkSignUpDetails(name, email, password) {
  if (!validateName(name)) {
    return 'Please leave your full name'
  }
  if (!validateEmail(email)) {
    return 'Please leave a valid email address'
  }
  if (!validatePassword(password)) {
    return 'Please leave a valid password'
  }
  return 'valid'
}

// Login

export const updateLoginForm = (value, type) => {
  const value_type = type === 'email' ? LOGIN_USERNAME : LOGIN_PASSWORD
  return {
    type: value_type,
    payload: value
  }
}

export const updateSignUpForm = (value, type) => {
  const value_type = type === 'email'
  ? SIGNUP_USERNAME
  : type === 'name'
    ? SIGNUP_NAME
    : SIGNUP_PASSWORD
  return {
    type: value_type,
    payload: value
  }
}

/**
 * @function loginFromTokenRequest
 * Login from token request FLUX standard action creator
 */
export const loginFromTokenRequest = () => {
  return {
    type: LOGIN_FROM_TOKEN_REQUEST
  }
}

/**
 * @function loginRequest
 * Login request FLUX standard action creator
 */
export const loginRequest = (email, password) => {
  if (checkLoginDetails(email, password) === 'valid') {
    return { type: LOGIN_REQUEST }
  } else {
    return { type: LOGIN_ERROR, payload: checkLoginDetails(email, password) }
  }
}

/**
 * @function signUpRequest
 * Login request FLUX standard action creator
 */
export const signUpRequest = (name, email, password) => {
  if (checkSignUpDetails(name, email, password) === 'valid') {
    return {
      type: SIGNUP_REQUEST
    }
  } else {
    return { type: SIGNUP_ERROR, payload: checkSignUpDetails(name, email, password) }
  }
}

export const updateUser = () => {
  return {
    type: UPDATE_USER
  }
}

/**
 * @function loginError
 * Login error FLUX standard action creator
 * @param {String} error
 */
export const loginError = payload => {
  return {
    type: LOGIN_ERROR,
    payload
  }
}

/**
 * @function loginSuccess
 */
export const loginSuccess = payload => ({ type: LOGIN_SUCCESS, payload })

// Logout

export const logoutRequest = () => {
  return {
    type: LOGOUT_REQUEST
  }
}

/**
 * @function logoutSuccess
 * Logout success FLUX standard action creator
 */
export const logoutSuccess = () => {
  return {
    type: LOGOUT_SUCCESS
  }
}

/**
 * @function signUpError
 * SignUp error FLUX standard action creator
 * @param {String} error
 */
export const signUpError = payload => {
  return {
    type: SIGNUP_ERROR,
    payload
  }
}
