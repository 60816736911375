import styled from 'styled-components'

export const Form = styled.form`
  max-width: 280px;
  input {
    margin-bottom: 12px;
  }
  > div {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
  }
`
