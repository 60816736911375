import styled from 'styled-components'
import { Main as _Main } from 'style'

export const Main = styled(_Main)`
  section {
    width: 80%;
    padding: 0 10% 10vh;
    h2 {
      width: 80%;
      padding: 0 10% 2.5vh;
      font-size: 40px;
      line-height: 48px;
      span {
        font-size: 20px;
        font-weight: bold;
      }
    }
    p {
      width: 65%;
      padding: 0 17.5%;
      text-align: justify;
      font-size: 18px;
      line-height: 28px;
    }
    img {
      width: 80%;
      margin-bottom: 2.5vh;
    }
  }

`
