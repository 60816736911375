import styled from 'styled-components'

export const Table = styled.table`
  width: 100%;
  @media (min-width: 800px) {
    width: 80%;
  }
  text-align: left;
  td {
    width: 20%;
    padding: 8px;
    &:nth-of-type(2) {
      width: 30%;
    }
    &:last-of-type {
      width: 10%;
    }
    &.full-width {
      text-align: center;
      width: 100%;
      border: 0;
      height: 100px;
      padding-top: 30px;
    }
  }
  thead tr {
    background-color: #EEE;
    border: 1px dotted #333;
    border-bottom: 0;
    font-weight: bold;
  }
  tbody tr {
    border: 1px dotted #333;
    border-top: 0;
  }
`
