import * as S from './Collection.styles'

function Collection() {
  return (
    <S.Main>
      <p>-- Collection Page --</p>
    </S.Main>
  )
}

export default Collection
