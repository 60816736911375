import { useEffect } from 'react'
import { connect } from 'react-redux'
import { getAuthorisationHeaders } from 'utils'
import { /* FETCHING, SUCCESS, ERROR, */ useApiRequest } from './actions'
// import loading from 'img/loading.svg'
// import * as S from './Components.styles'

const Components = ({ auth: { token }}) => {
  const [{ status/* , response */ }, getComponents] = useApiRequest(`/admin/components`,
    {
      verb: 'get',
      params: getAuthorisationHeaders(token)
    }
  )

  useEffect(() => status === null && getComponents(),[getComponents, status])

  return (
    <div />
  )
}

const connected = connect(({ auth }) => ({ auth }), null)(Components)
export default connected
