import React, { Component } from 'react'
import Orders from 'components/Orders'
import * as S from './Profile.styles'

class Profile extends Component {
  render() {
    return (
      <S.Main>
        <h3>-- Profile Page --</h3>
        <Orders />
      </S.Main>
    )
  }

}

export default Profile
