import * as S from './LoginForm.styles'

function LoginForm({ email, password, updateLoginForm, login, messages, errors }) {
  return (
    <S.Form onSubmit={e => e.preventDefault()}>
     <input
       type='text'
       placeholder='Email'
       value={email}
       onChange={e => updateLoginForm(e.target.value, 'email')}
     />
     <input
       type='password'
       placeholder='Password'
       value={password}
       onChange={e => updateLoginForm(e.target.value, 'password')}
     />
     <div>
       <button onClick={login}>{'Login'}</button>
     </div>
     {errors.length > 0 &&
       <p>- {!!errors[0].type.message ? 'Details not found. Please try again.' : errors[0].type} -</p>
     }
    </S.Form>
  )
}

export default LoginForm
