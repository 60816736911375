import React from 'react'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import LoginForm from 'components/LoginForm'
import SignUpForm from 'components/SignUpForm'
import { updateLoginForm, updateSignUpForm, loginRequest, signUpRequest } from 'App/auth/actions'
import loading from 'img/loading.svg'
import * as S from './Login.styles'

function Login({ updateLoginForm, updateSignUpForm, loginRequest, signUpRequest, auth }) {

  const signUp = () => signUpRequest(auth.signup.name, auth.signup.email, auth.signup.password)
  const login = () => loginRequest(auth.login.email, auth.login.password)

  const search = useLocation().search
  const msg = new URLSearchParams(search).get("msg")
  console.log(search, msg)

  return (
    <S.Main>
      <S.Div>
        <p>{msg === "1" ? 'Please login to create your order:': 'Already a remaker? Login here:'}</p>
        {auth.login.requesting
          ?
            <img src={loading} alt={''} data-testid={'login_loading'} />
          :
            <LoginForm
              email={auth.login.email}
              password={auth.login.password}
              messages={auth.login.messages}
              errors={auth.login.errors}
              {...{  updateLoginForm, login }}
            />
        }
      </S.Div>
      <S.Div>
        <p>{'Or sign up to become a remaker:'}</p>
        {auth.signup.requesting
          ?
            <img src={loading} alt={''} data-testid={'signup_loading'} />
          :
            <SignUpForm
              name={auth.signup.name}
              email={auth.signup.email}
              password={auth.signup.password}
              messages={auth.signup.messages}
              errors={auth.signup.errors}
             {...{ updateSignUpForm, signUp }}/>
        }
      </S.Div>
    </S.Main>
  )
}

const mapStateToProps = ({ auth }) => ({ auth })
const connected = connect(mapStateToProps, { updateLoginForm, updateSignUpForm, loginRequest, signUpRequest })(Login)
export default connected
