import React, { Component } from 'react'
import { Route, Link } from 'react-router-dom'
import Create from 'components/Create(Admin)'
import Components from 'components/Components(Admin)'
import Users from 'components/Users(Admin)'
import * as S from './Admin.styles'

class Admin extends Component {
  render() {
    return (
      <S.Main>
        <ul>
          <li><Link to="/admin/users">{'Remake Users'}</Link></li>
          <li><Link to="/admin/create">{'Create'}</Link></li>
          <li><Link to="/admin/components">{'Components'}</Link></li>
        </ul>
        <Route exact path={'/admin/users'} children={<Users />} />
        <Route exact path={'/admin/create'} children={<Create />} />
        <Route exact path={'/admin/components'} children={<Components />} />
        <Route exact path={'/admin/components/:type'} children={<Components />} />
      </S.Main>
    )
  }
}

export default Admin
