import { take, fork, call, put, select } from 'redux-saga/effects'
import { history } from 'store'
import { handleApiErrors } from './errors'
import {
  LOGIN_REQUEST,
  SIGNUP_REQUEST,
  LOGIN_FROM_TOKEN_REQUEST,
  LOGOUT_REQUEST,
  loginSuccess,
  loginError,
  logoutSuccess,
  signUpError
} from './actions'
import { baseRequest } from 'utils'

const LOGIN_SUCCESS_ROUTE = '/'
const TOKEN_KEY = 'token'

/**
 * @function _getTokenObjectFromStorage
 * Convenience method to return the parsed token data from local storage
 */
function _getTokenObjectFromStorage() {
  const tokenInStorage = localStorage.getItem(TOKEN_KEY)
  if (!tokenInStorage) {
    return null
  }
  return tokenInStorage
}

/**
 * @function _loginApi
 * Send the email/password to the API to attempt login
 * @param {String} email
 * @param {String} password
 */
function _loginApi(email, password) {
  return baseRequest
    .post('auth/login', { email, password })
    .then(handleApiErrors)
    .then(response => response.data)
}
/**
 * @function _signUpApi
 * Send the email/password to the API to attempt sign up
 * @param {String} email
 * @param {String} password
 */
function _signUpApi(name, email, password) {
  return baseRequest
    .post('auth/signup', { name, email, password })
    .then(handleApiErrors)
    .then(response => response.data)
}

/**
 * @generator _login
 * Control flow for the login process
 */
function* _login() {
  try {
    // Get our email & password from state
    const { email, password } = yield select(({ auth: { login } }) => login)
    // Blocking call to _loginApi generator
    // The flow will halt here until _loginApi returns a response
    const data = yield call(_loginApi, email, password)
    const token = data.token
    delete data.token
    // Save the token to login the user in the browser
    localStorage.setItem('token', token)
    // Dispatch the login success action
    // All required Redux reducers will respond to this action
    // We dispatch one Login specific action and then reducers act accordingly
    // Redirect user to the login success route
    yield put(loginSuccess(data))
    history.push(LOGIN_SUCCESS_ROUTE)
  } catch (error) {
    console.log(error)
    // Dispatch the login error action
    // All required Redux reducers will respond to this action
    // We dispatch one Login specific action and then reducers act accordingly
    yield put(loginError(error))
  }
}

/**
 * @generator _loginFromToken
 * Control flow for the login from token process
 */
function* _loginFromToken() {
  // Try to get the token from local storage
  let token = _getTokenObjectFromStorage()
  // If there is a token present then validate the token
  // If there is no token or the token is invalid then we should logout the user
  if (!token) {
    // Dispatch the logout success action
    yield put(logoutSuccess())
    return undefined
  }
  try {
    yield put(loginSuccess(token))
  } catch (e) {
    // TODO - check the error code is code is a 401
    yield put(logoutSuccess())
  }
}

/**
 * @function _logout
 * Logout the current user
 * TODO: Feels like this should hit the API and destroy the current session
 */
function* _logout() {
  // Remove the token to logout the user in the browser
  localStorage.removeItem(TOKEN_KEY)
  // Dispatch the logout success action
  // All required Redux reducers will respond to this action
  // We dispatch one Login specific action and then reducers act accordingly
  yield put(logoutSuccess())
  history.push('/')
}

/**
 * @generator _login
 * Control flow for the login process
 */
function* _signUp() {
  try {
    // Get our name, email & password from state
    const { name, email, password } = yield select(({ auth: { signup } }) => signup)
    // Blocking call to _loginApi generator
    // The flow will halt here until _loginApi returns a response
    yield call(_signUpApi, name, email, password)
    const data = yield call(_loginApi, email, password)
    const token = data.token
    delete data.token
    // Save the token to login the user in the browser
    localStorage.setItem('token', token)
    // Dispatch the login success action
    // All required Redux reducers will respond to this action
    // We dispatch one Login specific action and then reducers act accordingly
    // Redirect user to the login success route
    yield put(loginSuccess(data))
    history.push(LOGIN_SUCCESS_ROUTE)
  } catch (error) {
    // Dispatch the signup error action
    // All required Redux reducers will respond to this action
    // We dispatch one Login specific action and then reducers act accordingly
    yield put(signUpError(error))
  }
}

/**
 * @generator loginWatcher
 * Watches for login actions and acts accordingly
 * NOTE: This method should be thin, it does nothing other than catch actions
 * and then either fork (non-blocking) or call (blocking) to another generator function
 */
export default function* loginWatcher() {
  while (true) {
    // Take all the actions you want to handle in this watcher
    const { type } = yield take([
      LOGIN_REQUEST,
      LOGIN_FROM_TOKEN_REQUEST,
      LOGOUT_REQUEST,
      SIGNUP_REQUEST
    ])

    // Switch on the action type and fork/call other generators
    // Use *fork* for non-blocking and use *call* is blocking
    switch (type) {
      case LOGIN_REQUEST: {
        // Call to login generator
        yield fork(_login)
        break
      }
      case LOGIN_FROM_TOKEN_REQUEST: {
        // Call to login from token generator
        yield fork(_loginFromToken)
        break
      }
      case LOGOUT_REQUEST: {
        // Call to logout generator
        yield fork(_logout)
        break
      }
      case SIGNUP_REQUEST: {
        // Call to signup generator
        yield fork(_signUp)
        break
      }
      default:
        // Do nothing
        // This prevents the linter from choking
        break
    }
  }
}
