import React from 'react'
import { connect } from 'react-redux'
// import { find, isEmpty } from 'lodash'

/**
 * @var AUTH_HOST_NAMES
 * Add all host names that require authentication here
 */
// const AUTH_HOST_NAMES = ['localhost','stage.halfasecond.com','halfasecond.tech']

const WithAuth = (Component) => {
  class AuthComponent extends Component {
    /**
     * @inheritdoc
     */
    componentDidMount() {
      // Every time this component mounts we will check if there is a token
      // If there is no then we will redirect the user back to the login page...
      this._checkAuth();
    }

    /**
     * @function _checkAuth
     * Check whether the user is authenticated and redirect if not
     */
    _checkAuth() {
      // TODO: Remove console log here - For testing only...
      // console.log('WithAuthRequired: Checking if authenticated', this.props.client)

      // Exit if the domain host name does not require auth
      // i.e; If the host name is not within the {AUTH_HOST_NAMES} array
      // const isAuthHost = !isEmpty(find(
      //   AUTH_HOST_NAMES, (hostname) => hostname === window.location.hostname
      // ));
      // if (!isAuthHost) {
      //   return undefined;
      // }

      // Take a look in the props passed into the AuthComponent
      // If we have a token present then a user is authenticated
      // So we exit the function
      const token = localStorage.getItem('token')
      if (token) {
        return undefined;
      } else {
        console.log('no token found')
      }

      // If there is no token present then the user is not authenticated
      // In this case we redirect them to the login route with the current route appended
      // as a redirect route to use on successful login
      const { history, location } = this.props
      const { pathname, search } = location
      history.push(
        `/login?redirect=${pathname}${search}`
      );
    }

    /**
     * @inheritdoc
     */
    render() {
      const { token } = this.props.auth;
      if (token) {
        return (
          <Component { ...this.props } />
        );
      }
      return null;
    }
  }

  const mapStateToProps = ({ auth }) => ({ auth });
  return connect(mapStateToProps, {})(AuthComponent)
};

export default WithAuth
