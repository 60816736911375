import React, { useState, useEffect } from 'react'
import * as S from './Orders.style'
import { baseRequest, getAuthorisationHeaders } from 'utils'

const Orders = () => {
  const [loading, setLoading] = useState(true)
  // const [error, setError] = useState(false)
  const [orders, setOrders] = useState([])

  useEffect(() => {
    if (loading) {
      const { token } = localStorage
      baseRequest.post('api/orders', {}, getAuthorisationHeaders(token)).then(res => {
        setOrders([...res.data])
        setLoading(false)
      }).catch(console.log)
    }
  })

  return (
    <S.Div>
      <h3>{'Your Orders:'}</h3>
      {loading
        ? <p>{'Loading'}</p>
        : <div>{orders.map((order, i) => {
          return (
            <div key={i}>
              <div>{order._id}</div>
              {/* <div>{order.orderEmail}</div>
              <div>{order.orderBy}</div> */}
              <div>{order.bodice}</div>
              <div>{order.sleeve}</div>
              <div>{order.back}</div>
              <div>{order.swatch}</div>
              <div>{order.neckline}</div>
            </div>
          )
        })}</div>
      }
    </S.Div>
  )
}

export default Orders
