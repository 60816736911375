import styled from 'styled-components'
import { Main as _Main } from 'style'

export const Main = styled(_Main)`
  img {
    width: 100%;
    &:first-of-type {
      width: 35%;
      margin-bottom: 50px;
    }
  }
`
