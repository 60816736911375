import img from 'img/paperdoll/masterdummy.svg'
import * as S from './Home.styles'

function Home() {
  return (
    <S.Main>
      <img src={img} alt={''} />
    </S.Main>
  )
}

export default Home
