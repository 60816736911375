import { Link } from 'react-router-dom'
import { links } from './Menu.links'
import * as S from './Menu.style'

function Menu() {
  return (
    <S.Ul>
      {links.map((link, i) => <li key={i}><Link to={link.url}>{link.title}</Link></li>)}
    </S.Ul>
  )
}

export default Menu
