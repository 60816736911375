import { useEffect } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { getAuthorisationHeaders } from 'utils'
import { FETCHING, SUCCESS, ERROR, useApiRequest } from './actions'
import loading from 'img/loading.svg'
import * as S from './Users.styles'

const Users = ({ auth: { token }}) => {
  const [{ status, response }, getUsers] = useApiRequest(`/admin/users`,
    {
      verb: 'get',
      params: getAuthorisationHeaders(token)
    }
  )

  useEffect(() => status === null && (
    getUsers()
  ),[getUsers, status])

  return (
    <S.Table>
      <thead>
        <tr>
          <td>{'Name'}</td>
          <td>{'Email'}</td>
          <td>{'Date Joined'}</td>
          <td>{'Active'}</td>
          <td>{'Completed'}</td>
        </tr>
      </thead>
      <tbody>
      {status === FETCHING &&
        <tr>
          <td colSpan={5} className={'full-width'}>
            <img src={loading} alt={''} />
          </td>
        </tr>
      }
        {status === ERROR &&
          <tr>
            <td colSpan={5} className={'full-width'}>
              {'There was an error'}
            </td>
          </tr>
        }
        {status === SUCCESS &&
          response.data.map(({ name, email, active, createdAt }, i) =>
            <tr key={i}>
              <td>{name}</td>
              <td>{email}</td>
              <td>{moment(new Date(createdAt)).format('MMM Do YYYY')}</td>
              <td>{active}</td>
              <td></td>
            </tr>
          )
        }
      </tbody>
    </S.Table>
  )
}

const connected = connect(({ auth }) => ({ auth }), null)(Users)
export default connected
