import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import StepsMenu from 'components/CreateSteps'
import * as S from './Create.styles'
import { bodices, sleeves, backs, swatches, necklines } from './data'
import { baseRequest, getAuthorisationHeaders } from 'utils'
import { history } from 'store'

const data = { bodices, sleeves, backs, swatches, necklines }

const getPrice = (bodice, sleeve, swatch, neckline, back) => {
  let price = 0
  if (bodice >= -1 && bodices[bodice]) {
    price = price + bodices[bodice].price
  }
  if (sleeve >= -1 && sleeves[sleeve]) {
    price = price + sleeves[sleeve].price
  }
  if (swatch >= -1 && swatches[swatch]) {
    price = price + swatches[swatch].price
  }
  if (neckline >= -1 && necklines[neckline]) {
    price = price + necklines[neckline].price
  }
  if (back >= -1 && backs[back]) {
    price = price + backs[back].price
  }
  return price
}

const getImage = ({ imgSrc, name }, type) => <img id={type} src={imgSrc} alt={name} className={'fadeIn'} />

const getBackSection = ({ imgSrc, name }, type, backgroundImage) =>
  <div className="backSection" style={{ backgroundImage: `url(${backgroundImage})` }}>
    <img id={type} src={imgSrc} alt={name} className={'fadeIn'} />
  </div>

function Create({ props: { match: { params: { type }}}, auth: { token }}) {
  const [bodice, setBodice] = useState(-1)
  const [neckline, setNeckline] = useState(-1)
  const [back, setBack] = useState(-1)
  const [sleeve, setSleeve] = useState(-1)
  const [swatch, setSwatch] = useState(-1)
  const [createRequest, setCreateRequest] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)


  useEffect(() => {
    if (createRequest && !loading) {
      const { token } = localStorage
      if (token) {
        setLoading(true)
        baseRequest.post('/api/order', {
          bodice: bodices[bodice].name,
          bodicePrice: bodices[bodice].price,
          sleeve: sleeve === -1 ? sleeves[0].name : sleeves[sleeve].name,
          sleevePrice: sleeve === -1 ? sleeves[0].price : sleeves[sleeve].price,
          swatch: swatches[swatch].name,
          swatchPrice: swatches[swatch].price,
          neckline: necklines[neckline].name,
          necklinePrice: necklines[neckline].price,
          back: backs[back].name,
          backPrice: backs[back].price
        }, getAuthorisationHeaders(token)).then(res => {
          setCreateRequest(false)
          setLoading(false)
          setSuccess(true)
        }).catch(e => {
          setError(e)
          console.log(error)
        })
      } else {
        history.push('/login?msg=1')
      }
    }
  },[createRequest, loading, bodice, sleeve, swatch, neckline, back, error])

  return (
    <S.Main>
      {!createRequest && !success ?
        <>
          <S.Tailor>
            {bodice >= 0 && getImage(bodices[bodice], 'bodice')}
            {sleeve >= 0 && getImage(sleeves[sleeve], 'sleeve')}
            {neckline >= 0 && getImage(necklines[neckline], 'neckline')}
            <div className={'swatch'}>{swatch > -1 && <img src={swatches[swatch].imgSrc} alt={swatches[swatch].name} />}</div>
            {back >= 0 && getBackSection(backs[back], 'back', bodices[bodice].reverse)}
            <div className={"price"}>
              <b>Total:</b>
              {bodice >= 0 &&
                <p>{bodices[bodice].name} bodice - £{bodices[bodice].price}</p>
              }
              {neckline >= 0 &&
                <p>{necklines[neckline].name} - £{necklines[neckline].price}</p>
              }
              {back >= 0 &&
                <p>{backs[back].name} back - £{backs[back].price}</p>
              }
              {sleeve >= 0 &&
                <p>{sleeves[sleeve].name} sleeve - £{sleeves[sleeve].price}</p>
              }
              {swatch >= 0 &&
                <p>{swatches[swatch].name} - £{swatches[swatch].price}</p>
              }
              {<b>Price: £{getPrice(bodice, sleeve, swatch, neckline, back)}</b>}
              <button disabled={!(bodice >= 0 && neckline >= 0 && swatch > -1 && back >= 0)} onClick={() => setCreateRequest(true)}>{'Create'}</button>

            </div>
            {(bodice >= 0 || neckline >= 0 || (sleeve >= 0 && sleeves[sleeve].refImgsrc)) &&
              <div className="refs">
                {bodice >=0 &&
                  <div>
                    <img src={bodices[bodice].refImgsrc} alt={'Bodice Reference'} />
                    <p>{bodices[bodice].name}</p>
                  </div>
                }
                {neckline >= 0 &&
                  <div>
                    <img src={necklines[neckline].refImgsrc} alt={'Neckline Reference'} />
                    <p>{necklines[neckline].name}</p>
                  </div>
                }
                {sleeve >= 0 && sleeves[sleeve].refImgsrc &&
                  <div>
                    <img src={sleeves[sleeve].refImgsrc} alt={'Sleeves Reference'} />
                    <p>{sleeves[sleeve].name}</p>
                  </div>
                }
              </div>
            }
          </S.Tailor>
          <StepsMenu {...{ data, setBodice, setSleeve, setBack, setSwatch, setNeckline, neckline, necklines, bodices, bodice }} />
        </>
        :
          success
          ? <>
          <S.Order>
            {bodice >= 0 && getImage(bodices[bodice], 'bodice')}
            {sleeve >= 0 && getImage(sleeves[sleeve], 'sleeve')}
            {neckline >= 0 && getImage(necklines[neckline], 'neckline')}
            <div className={"success"}>
              <h3>{'Thanks for placing your order'}</h3>
              <p>{'Our designers will now create a 3D visual of your garment, presented on an avatar that represents your unique shape. Your personalised 3D garment design will then be sent to your email - please conatct us if it has not arrived after 2 business days.'}</p>
            </div>
            <div className={"price"}>
              <b>Total:</b>
              {bodice >= 0 &&
                <p>{bodices[bodice].name} bodice - £{bodices[bodice].price}</p>
              }
              {neckline >= 0 &&
                <p>{necklines[neckline].name} - £{necklines[neckline].price}</p>
              }
              {back >= 0 &&
                <p>{backs[back].name} back - £{backs[back].price}</p>
              }
              {sleeve >= 0 &&
                <p>{sleeves[sleeve].name} sleeve - £{sleeves[sleeve].price}</p>
              }
              {swatch >= 0 &&
                <p>{swatches[swatch].name} - £{swatches[swatch].price}</p>
              }
              {<b>Price: £{getPrice(bodice, sleeve, swatch, neckline, back)}</b>}
            </div>
          </S.Order>
            </>
          : <p>{'Loading...'}</p>
      }
    </S.Main>
  )
}

const mapStateToProps = ({ auth }) => ({ auth })
const connected = connect(mapStateToProps, null)(Create)
export default connected
