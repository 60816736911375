import * as S from './Remake.styles'

function Remake() {
  return (
    <S.Main>
      <p>-- Remake Page --</p>
    </S.Main>
  )
}

export default Remake
