import bodices1 from 'assets/bodices/bodices1.png'
import bodices2 from 'assets/bodices/bodices2.png'
import bodices3 from 'assets/bodices/bodices3.png'
import bodices4 from 'assets/bodices/bodices4.png'
import bodices5 from 'assets/bodices/bodices5.png'
import bodices6 from 'assets/bodices/bodices6.png'
import bodices7 from 'assets/bodices/bodices7.png'
import bodices8 from 'assets/bodices/bodices8.png'
import bodices9 from 'assets/bodices/bodices9.png'
import bodices10 from 'assets/bodices/bodices10.png'
import bodices11 from 'assets/bodices/bodices11.png'
import bodices12 from 'assets/bodices/bodices12.png'
import bodices13 from 'assets/bodices/bodices13.png'
import bodices14 from 'assets/bodices/bodices14.png'

import backbodices1 from 'assets/bodices/backbodices1.png'
import backbodices2 from 'assets/bodices/backbodices2.png'
import backbodices3 from 'assets/bodices/backbodices3.png'
import backbodices4 from 'assets/bodices/backbodices4.png'
import backbodices5 from 'assets/bodices/backbodices5.png'
import backbodices6 from 'assets/bodices/backbodices6.png'
import backbodices7 from 'assets/bodices/backbodices7.png'
import backbodices8 from 'assets/bodices/backbodices8.png'
import backbodices9 from 'assets/bodices/backbodices9.png'

import neckline1 from 'assets/necklines/neckline1.png'
import neckline2 from 'assets/necklines/neckline2.png'
import neckline3 from 'assets/necklines/neckline3.png'
import neckline4 from 'assets/necklines/neckline4.png'
import neckline5 from 'assets/necklines/neckline5.png'
import neckline6 from 'assets/necklines/neckline6.png'
import neckline7 from 'assets/necklines/neckline7.png'
import neckline8 from 'assets/necklines/neckline8.png'
import neckline9 from 'assets/necklines/neckline9.png'
import neckline10 from 'assets/necklines/neckline10.png'
import neckline11 from 'assets/necklines/neckline11.png'
import neckline12 from 'assets/necklines/neckline12.png'

import sleeves1 from 'assets/sleeves/sleeves1.png'
import sleeves2 from 'assets/sleeves/sleeves2.png'
import sleeves3 from 'assets/sleeves/sleeves3.png'
import sleeves4 from 'assets/sleeves/sleeves4.png'
import sleeves5 from 'assets/sleeves/sleeves5.png'
import sleeves6 from 'assets/sleeves/sleeves6.png'
import sleeves7 from 'assets/sleeves/sleeves7.png'
import sleeves8 from 'assets/sleeves/sleeves8.png'
import sleeves9 from 'assets/sleeves/sleeves9.png'
import sleeves10 from 'assets/sleeves/sleeves10.png'
import sleeves11 from 'assets/sleeves/sleeves11.png'
import sleeves12 from 'assets/sleeves/sleeves12.png'
import sleeves13 from 'assets/sleeves/sleeves13.png'
import sleeves14 from 'assets/sleeves/sleeves14.png'
import sleeves15 from 'assets/sleeves/sleeves15.png'
import sleeves16 from 'assets/sleeves/sleeves16.png'

import backs1 from 'assets/backs/back1.png'
import backs2 from 'assets/backs/back2.png'
//import backs3 from 'assets/backs/back3.png'
import backs4 from 'assets/backs/back4.png'
import backs5 from 'assets/backs/back5.png'
import backs6 from 'assets/backs/back6.png'
//import backs7 from 'assets/backs/back7.png'
import backs8 from 'assets/backs/back8.png'
import backs9 from 'assets/backs/back9.png'
import backs10 from 'assets/backs/back10.png'
import backs11 from 'assets/backs/back11.png'
import backs12 from 'assets/backs/back12.png'
import backs13 from 'assets/backs/back13.png'
import backs14 from 'assets/backs/back14.png'
import backs15 from 'assets/backs/back15.png'
import backs16 from 'assets/backs/back16.png'
import backs17 from 'assets/backs/back17.png'

import bodices_ref1 from 'assets/bodices/bodices_ref1.jpg'
import bodices_ref2 from 'assets/bodices/bodices_ref2.jpg'
import bodices_ref3 from 'assets/bodices/bodices_ref3.jpg'
import bodices_ref4 from 'assets/bodices/bodices_ref4.jpg'
import bodices_ref5 from 'assets/bodices/bodices_ref5.jpg'
import bodices_ref6 from 'assets/bodices/bodices_ref6.jpg'
import bodices_ref7 from 'assets/bodices/bodices_ref7.jpg'
import bodices_ref8 from 'assets/bodices/bodices_ref8.jpg'
import bodices_ref9 from 'assets/bodices/bodices_ref9.jpg'

import neckline_ref1 from 'assets/necklines/neckline_ref1.jpg'
import neckline_ref2 from 'assets/necklines/neckline_ref2.jpg'
import neckline_ref3 from 'assets/necklines/neckline_ref3.jpg'
import neckline_ref4 from 'assets/necklines/neckline_ref4.jpg'
import neckline_ref5 from 'assets/necklines/neckline_ref5.jpg'
import neckline_ref6 from 'assets/necklines/neckline_ref6.jpg'
import neckline_ref7 from 'assets/necklines/neckline_ref7.jpg'
import neckline_ref8 from 'assets/necklines/neckline_ref8.jpg'
import neckline_ref9 from 'assets/necklines/neckline_ref9.jpg'
import neckline_ref10 from 'assets/necklines/neckline_ref10.jpg'
import neckline_ref11 from 'assets/necklines/neckline_ref11.jpg'

import sleeves_ref1 from 'assets/sleeves/sleeves_ref1.jpg'
import sleeves_ref2 from 'assets/sleeves/sleeves_ref2.jpg'
import sleeves_ref3 from 'assets/sleeves/sleeves_ref3.jpg'
import sleeves_ref4 from 'assets/sleeves/sleeves_ref4.jpg'
import sleeves_ref5 from 'assets/sleeves/sleeves_ref5.jpg'
import sleeves_ref6 from 'assets/sleeves/sleeves_ref6.jpg'
import sleeves_ref7 from 'assets/sleeves/sleeves_ref7.jpg'
import sleeves_ref8 from 'assets/sleeves/sleeves_ref8.jpg'
import sleeves_ref9 from 'assets/sleeves/sleeves_ref9.jpg'
import sleeves_ref10 from 'assets/sleeves/sleeves_ref10.jpg'
import sleeves_ref11 from 'assets/sleeves/sleeves_ref11.jpg'
import sleeves_ref12 from 'assets/sleeves/sleeves_ref12.jpg'
import sleeves_ref13 from 'assets/sleeves/sleeves_ref13.jpg'
import sleeves_ref14 from 'assets/sleeves/sleeves_ref14.jpg'
import sleeves_ref15 from 'assets/sleeves/sleeves_ref15.jpg'

import swatch3 from 'img/swatches/swatches/ch3.jpg'
import swatch5 from 'img/swatches/swatches/ch5.jpg'
import swatch6 from 'img/swatches/swatches/ch6.jpg'
import swatch7 from 'img/swatches/swatches/ch7.jpg'
import swatch8 from 'img/swatches/swatches/ch8.jpg'
import swatch9 from 'img/swatches/swatches/ch9.jpg'
import swatch10 from 'img/swatches/swatches/ch10.jpg'
import swatch11 from 'img/swatches/swatches/ch11.jpg'
import swatch12 from 'img/swatches/swatches/ch12.jpg'
import swatch13 from 'img/swatches/swatches/ch13.jpg'
import swatch14 from 'img/swatches/swatches/ch14.jpg'
import swatch15 from 'img/swatches/swatches/ch15.jpg'
import swatch16 from 'img/swatches/swatches/ch16.jpg'
import swatch17 from 'img/swatches/swatches/ch17.jpg'
import swatch18 from 'img/swatches/swatches/ch18.jpg'
import swatch19 from 'img/swatches/swatches/ch19.jpg'

import swatch20 from 'img/swatches/swatches/ch20.jpg'
import swatch21 from 'img/swatches/swatches/ch21.jpg'
import swatch22 from 'img/swatches/swatches/ch22.jpg'
import swatch23 from 'img/swatches/swatches/ch23.jpg'
import swatch24 from 'img/swatches/swatches/ch24.jpg'
import swatch25 from 'img/swatches/swatches/ch25.jpg'
import swatch26 from 'img/swatches/swatches/ch26.jpg'
import swatch27 from 'img/swatches/swatches/ch27.jpg'
import swatch28 from 'img/swatches/swatches/ch28.jpg'
import swatch29 from 'img/swatches/swatches/ch29.jpg'
import swatch30 from 'img/swatches/swatches/ch30.jpg'
import swatch31 from 'img/swatches/swatches/ch31.jpg'
import swatch32 from 'img/swatches/swatches/ch32.jpg'
import swatch33 from 'img/swatches/swatches/ch33.jpg'
import swatch34 from 'img/swatches/swatches/ch34.jpg'
import swatch35 from 'img/swatches/swatches/ch35.jpg'
import swatch36 from 'img/swatches/swatches/ch36.jpg'
import swatch37 from 'img/swatches/swatches/ch37.jpg'
import swatch38 from 'img/swatches/swatches/ch38.jpg'
import swatch39 from 'img/swatches/swatches/ch39.jpg'
import swatch40 from 'img/swatches/swatches/ch40.jpg'
import swatch41 from 'img/swatches/swatches/ch41.jpg'
import swatch42 from 'img/swatches/swatches/ch42.jpg'
import swatch43 from 'img/swatches/swatches/ch43.jpg'
import swatch44 from 'img/swatches/swatches/ch44.jpg'
import swatch45 from 'img/swatches/swatches/ch45.jpg'
import swatch46 from 'img/swatches/swatches/ch46.jpg'
import swatch47 from 'img/swatches/swatches/ch47.jpg'
import swatch48 from 'img/swatches/swatches/ch48.jpg'
import swatch49 from 'img/swatches/swatches/ch49.jpg'
import swatch50 from 'img/swatches/swatches/ch50.jpg'
import swatch51 from 'img/swatches/swatches/ch51.jpg'
import swatch52 from 'img/swatches/swatches/ch52.jpg'
import swatch53 from 'img/swatches/swatches/ch53.jpg'
import swatch54 from 'img/swatches/swatches/ch54.jpg'
import swatch55 from 'img/swatches/swatches/ch55.jpg'
import swatch56 from 'img/swatches/swatches/ch56.jpg'
import swatch57 from 'img/swatches/swatches/ch57.jpg'
import swatch58 from 'img/swatches/swatches/ch58.jpg'
import swatch59 from 'img/swatches/swatches/ch59.jpg'
// import swatch60 from 'img/swatches/swatches/ch60.jpg'
// import swatch61 from 'img/swatches/swatches/ch61.jpg'
// import swatch62 from 'img/swatches/swatches/ch62.jpg'
// import swatch63 from 'img/swatches/swatches/ch63.jpg'
// import swatch64 from 'img/swatches/swatches/ch64.jpg'
// import swatch65 from 'img/swatches/swatches/ch65.jpg'
// import swatch66 from 'img/swatches/swatches/ch66.jpg'
// import swatch67 from 'img/swatches/swatches/ch67.jpg'
// import swatch68 from 'img/swatches/swatches/ch68.jpg'
// import swatch69 from 'img/swatches/swatches/ch69.jpg'
import swatch70 from 'img/swatches/swatches/ch70.jpg'
import swatch71 from 'img/swatches/swatches/ch71.jpg'
import swatch72 from 'img/swatches/swatches/ch72.jpg'
import swatch73 from 'img/swatches/swatches/ch73.jpg'
import swatch74 from 'img/swatches/swatches/ch74.jpg'
import swatch75 from 'img/swatches/swatches/ch75.jpg'
import swatch76 from 'img/swatches/swatches/ch76.jpg'
import swatch77 from 'img/swatches/swatches/ch77.jpg'
import swatch78 from 'img/swatches/swatches/ch78.jpg'
import swatch79 from 'img/swatches/swatches/ch79.jpg'
import swatch80 from 'img/swatches/swatches/ch80.jpg'
import swatch81 from 'img/swatches/swatches/ch81.jpg'
import swatch82 from 'img/swatches/swatches/ch82.jpg'
import swatch83 from 'img/swatches/swatches/ch83.jpg'
import swatch84 from 'img/swatches/swatches/ch84.jpg'
import swatch85 from 'img/swatches/swatches/ch85.jpg'
import swatch86 from 'img/swatches/swatches/ch86.jpg'
import swatch87 from 'img/swatches/swatches/ch87.jpg'
import swatch88 from 'img/swatches/swatches/ch88.jpg'
import swatch89 from 'img/swatches/swatches/ch89.jpg'
import swatch90 from 'img/swatches/swatches/ch90.jpg'
import swatch91 from 'img/swatches/swatches/ch91.jpg'
import swatch92 from 'img/swatches/swatches/ch92.jpg'
import swatch93 from 'img/swatches/swatches/ch93.jpg'
import swatch94 from 'img/swatches/swatches/ch94.jpg'
import swatch95 from 'img/swatches/swatches/ch95.jpg'
import swatch96 from 'img/swatches/swatches/ch96.jpg'
import swatch97 from 'img/swatches/swatches/ch97.jpg'
import swatch98 from 'img/swatches/swatches/ch98.jpg'
import swatch99 from 'img/swatches/swatches/ch99.jpg'
import swatch100 from 'img/swatches/swatches/ch100.jpg'
import swatch101 from 'img/swatches/swatches/ch101.jpg'
import swatch102 from 'img/swatches/swatches/ch102.jpg'
import swatch103 from 'img/swatches/swatches/ch103.jpg'
import swatch104 from 'img/swatches/swatches/ch104.jpg'
import swatch105 from 'img/swatches/swatches/ch105.jpg'
import swatch106 from 'img/swatches/swatches/ch106.jpg'
import swatch107 from 'img/swatches/swatches/ch107.jpg'
import swatch108 from 'img/swatches/swatches/ch108.jpg'
import swatch109 from 'img/swatches/swatches/ch109.jpg'
import swatch110 from 'img/swatches/swatches/ch110.jpg'
import swatch111 from 'img/swatches/swatches/ch111.jpg'
import swatch112 from 'img/swatches/swatches/ch112.jpg'
import swatch113 from 'img/swatches/swatches/ch113.jpg'
import swatch114 from 'img/swatches/swatches/ch114.jpg'
import swatch115 from 'img/swatches/swatches/ch115.jpg'
import swatch116 from 'img/swatches/swatches/ch116.jpg'
import swatch117 from 'img/swatches/swatches/ch117.jpg'
import swatch118 from 'img/swatches/swatches/ch118.jpg'
import swatch119 from 'img/swatches/swatches/ch119.jpg'
import swatch120 from 'img/swatches/swatches/ch120.jpg'
import swatch121 from 'img/swatches/swatches/ch121.jpg'
import swatch122 from 'img/swatches/swatches/ch122.jpg'
import swatch123 from 'img/swatches/swatches/ch123.jpg'
import swatch124 from 'img/swatches/swatches/ch124.jpg'
import swatch125 from 'img/swatches/swatches/ch125.jpg'
import swatch126 from 'img/swatches/swatches/ch126.jpg'
import swatch127 from 'img/swatches/swatches/ch127.jpg'
import swatch128 from 'img/swatches/swatches/ch128.jpg'
import swatch129 from 'img/swatches/swatches/ch129.jpg'
import swatch130 from 'img/swatches/swatches/ch130.jpg'
import swatch131 from 'img/swatches/swatches/ch131.jpg'
import swatch132 from 'img/swatches/swatches/ch132.jpg'
import swatch133 from 'img/swatches/swatches/ch133.jpg'
import swatch134 from 'img/swatches/swatches/ch134.jpg'
import swatch135 from 'img/swatches/swatches/ch135.jpg'
import swatch136 from 'img/swatches/swatches/ch136.jpg'
import swatch137 from 'img/swatches/swatches/ch137.jpg'


export const bodices = [
  {
    name: 'Empire',
    permitted:['Classic Turtle','Pointed Collar','Round Collar','Pussy Bow','VintageTurtle','Bateau','Cowl','Wide Scoop','Wide V','Narrow Crew','Narrow V',],
    imgSrc: bodices1,
    price: 50,
    reverse: backbodices1,
    refImgsrc: bodices_ref1
  },
  {
    name: 'French Tuck',
    permitted:['Classic Turtle','Pointed Collar','Round Collar','Pussy Bow','VintageTurtle','Bateau','Cowl','Wide Scoop','Wide V','Narrow Crew','Narrow V',],
    imgSrc: bodices2,
    price: 40,
    reverse: backbodices2,
    refImgsrc: bodices_ref2

  },
  {
    name: 'Fish Tail',
    permitted:['Classic Turtle','Pointed Collar','Round Collar','Pussy Bow','VintageTurtle','Bateau','Cowl','Wide Scoop','Wide V','Narrow Crew','Narrow V',],
    imgSrc: bodices3,
    price: 30,
    reverse: backbodices3,
    refImgsrc: bodices_ref3
  },
  {
    name: 'A Line',
    permitted:['Classic Turtle','Pointed Collar','Round Collar','Pussy Bow','VintageTurtle','Bateau','Cowl','Wide Scoop','Wide V','Narrow Crew','Narrow V',],
    imgSrc: bodices4,
    price: 20,
    reverse: backbodices4,
    refImgsrc: bodices_ref4
  },
  {
    name: 'Peplum',
    permitted:['Classic Turtle','Pointed Collar','Round Collar','Pussy Bow','VintageTurtle','Bateau','Cowl','Wide Scoop','Wide V','Narrow Crew','Narrow V',],
    imgSrc: bodices5,
    price: 35,
    reverse: backbodices5,
    refImgsrc: bodices_ref5
  },
  {
    name: 'Straight',
    permitted:['Classic Turtle','Pointed Collar','Round Collar','Pussy Bow','VintageTurtle','Bateau','Cowl','Wide Scoop','Wide V','Narrow Crew','Narrow V',],
    imgSrc: bodices6,
    price: 35,
    reverse: backbodices6,
    refImgsrc: bodices_ref6
  },
  {
    name: 'Feminine',
    permitted:['Classic Turtle','Pointed Collar','Round Collar','Pussy Bow','VintageTurtle','Bateau','Cowl','Wide Scoop','Wide V','Narrow Crew','Narrow V',],
    imgSrc: bodices7,
    price: 35,
    reverse: backbodices7,
    refImgsrc: bodices_ref7
  },
  {
    name: 'Fitted Boxy',
    permitted:['Classic Turtle','Pointed Collar','Round Collar','Pussy Bow','VintageTurtle','Bateau','Cowl','Wide Scoop','Wide V','Narrow Crew','Narrow V',],
    imgSrc: bodices8,
    price: 35,
    reverse: backbodices8,
    refImgsrc: bodices_ref8
  },
  {
    name: 'Boxy',
    permitted:['Classic Turtle','Pointed Collar','Round Collar','Pussy Bow','VintageTurtle','Bateau','Cowl','Wide Scoop','Wide V','Narrow Crew','Narrow V',],
    imgSrc: bodices9,
    price: 35,
    reverse: backbodices9,
    refImgsrc: bodices_ref9
  },
  {
    name: 'Empire Button Front',
    permitted: ['Pointed Collar','Round Collar','Pussy Bow','Wide Scoop','Wide V','Narrow Crew','Narrow V',],
    imgSrc: bodices10,
    price: 55,
    reverse: backbodices2,
    refImgsrc: bodices_ref2
  },
  {
    name: 'Straight Button Front',
    permitted: ['Pointed Collar','Round Collar','Pussy Bow','Wide Scoop','Wide V','Narrow Crew','Narrow V',],
    imgSrc: bodices11,
    price: 45,
    reverse: backbodices6,
    refImgsrc: bodices_ref6
  },
  {
    name: 'Feminine Button Front',
    permitted: ['Pointed Collar','Round Collar','Pussy Bow','Wide Scoop','Wide V','Narrow Crew','Narrow V',],
    imgSrc: bodices12,
    price: 45,
    reverse: backbodices7,
    refImgsrc: bodices_ref7
  },
  {
    name: 'Fitted Boxy Button Front',
    permitted: ['Pointed Collar','Round Collar','Pussy Bow','Wide Scoop','Wide V','Narrow Crew','Narrow V',],
    imgSrc: bodices13,
    price: 45,
    reverse: backbodices8,
    refImgsrc: bodices_ref8
  },
  {
    name: 'Boxy Button Front',
    permitted: ['Pointed Collar','Round Collar','Pussy Bow','Wide Scoop','Wide V','Narrow Crew','Narrow V',],
    imgSrc: bodices14,
    price: 45,
    reverse: backbodices9,
    refImgsrc: bodices_ref9
  },
]

export const necklines = [
  {
    name: 'Classic Turtle',
    imgSrc: neckline1,
    price: 50,
    permitted: ['Classic Turtle'],
    refImgsrc: neckline_ref1
  },
  {
    name: 'Pointed Collar',
    imgSrc: neckline2,
    price: 50,
    permitted: ['Collar'],
    refImgsrc: neckline_ref2
  },
  {
    name: 'Round Collar',
    imgSrc: neckline3,
    price: 50,
    permitted: ['Collar'],
    refImgsrc: neckline_ref3
  },
  {
    name: 'Pussy Bow',
    imgSrc: neckline4,
    price: 50,
    permitted: ['Pussy Bow'],
    refImgsrc: neckline_ref4
  },
  {
    name: 'VintageTurtle',
    imgSrc: neckline5,
    price: 50,
    permitted: ['Vintage Turtle'],
    refImgsrc: neckline_ref5
  },
  {
    name: 'Bateau',
    imgSrc: neckline6,
    price: 50,
    permitted: ['Bateau','Wide Scoop','Wide V'],
    refImgsrc: neckline_ref6
  },
  {
    name: 'Cowl',
    imgSrc: neckline7,
    price: 50,
    permitted: ['Bateau','Wide Scoop','Wide V'],
    refImgsrc: neckline_ref7
  },
  {
    name: 'Wide Scoop',
    imgSrc: neckline8,
    price: 50,
    permitted: ['Bateau','Wide Scoop','Wide V'],
    refImgsrc: neckline_ref8
  },
  {
    name: 'Wide V',
    imgSrc: neckline9,
    price: 50,
    permitted: ['Bateau','Wide Scoop','Wide V'],
    refImgsrc: neckline_ref9
  },
  {
    name: 'Narrow Crew',
    imgSrc: neckline10,
    price: 50,
    permitted: ['Narrow Crew','Narrow V','Keyhole','Button Slit','3 Button','Crew Full Button','Bateau Full Button'],
    refImgsrc: neckline_ref10
  },
  {
    name: 'Narrow V',
    imgSrc: neckline11,
    price: 50,
    permitted: ['Narrow Crew','Narrow V','Keyhole','Button Slit','3 Button','Crew Full Button','Bateau Full Button'],
    refImgsrc: neckline_ref11
  },
  {
    name: 'Wide Funnel',
    imgSrc: neckline12,
    price: 50,
    permitted: ['Wide Funnel'],
    refImgsrc: neckline_ref11
  },
]

export const backs = [
  {
    name: 'Classic Turtle',
    imgSrc: backs1,
    price: 50
  },
  {
    name: 'Collar',
    imgSrc: backs2,
    price: 40
  },
  // {
  //   name: 'Fish Tail',
  //   imgSrc: backs3,
  //   price: 30
  // },
  {
    name: 'Pussy Bow',
    imgSrc: backs4,
    price: 20
  },
  {
    name: 'Vintage Turtle',
    imgSrc: backs5,
    price: 35
  },
  {
    name: 'Bateau',
    imgSrc: backs6,
    price: 35
  },
  // {
  //   name: 'Feminine',
  //   imgSrc: backs7,
  //   price: 35
  // },
  {
    name: 'Wide Scoop',
    imgSrc: backs8,
    price: 35
  },
  {
    name: 'Wide V',
    imgSrc: backs9,
    price: 35
  },
  {
    name: 'Narrow Crew',
    imgSrc: backs10,
    price: 50,
  },
  {
    name: 'Narrow V',
    imgSrc: backs11,
    price: 40
  },
  {
    name: 'Wide Funnel',
    imgSrc: backs12,
    price: 30
  },
  {
    name: 'Button Slit',
    imgSrc: backs13,
    price: 20
  },
  {
    name: '3 Button',
    imgSrc: backs14,
    price: 35
  },
  {
    name: 'Crew Full Button',
    imgSrc: backs15,
    price: 35
  },
  {
    name: 'Bateau Full Button',
    imgSrc: backs16,
    price: 35
  },
  {
    name: 'Keyhole',
    imgSrc: backs17,
    price: 35
  }
]

export const sleeves = [
  {
    name: 'No Sleeves',
    imgSrc: sleeves16,
    price: 0,
    refImgsrc: false
  },
  {
    name: 'A Line (Short)',
    imgSrc: sleeves1,
    price: 50,
    refImgsrc: sleeves_ref1
  },
  {
    name: 'A Line (Half)',
    imgSrc: sleeves2,
    price: 40,
    refImgsrc: sleeves_ref2
  },
  {
    name: 'A Line (Mid)',
    imgSrc: sleeves3,
    price: 25,
    refImgsrc: sleeves_ref3
  },
  {
    name: 'A Line (Long)',
    imgSrc: sleeves4,
    price: 30,
    refImgsrc: sleeves_ref4
  },
  {
    name: 'Cap',
    imgSrc: sleeves5,
    price: 40,
    refImgsrc: sleeves_ref5
  },
  {
    name: 'Classic (Mid)',
    imgSrc: sleeves6,
    price: 40,
    refImgsrc: sleeves_ref6
  },
  {
    name: 'Classic (Long)',
    imgSrc: sleeves7,
    price: 40,
    refImgsrc: sleeves_ref7
  },
  {
    name: 'Straight (Long)',
    imgSrc: sleeves8,
    price: 40,
    refImgsrc: sleeves_ref8
  },
  {
    name: 'Straight (Short)',
    imgSrc: sleeves9,
    price: 40,
    refImgsrc: sleeves_ref9
  },
  {
    name: 'Straight (Half)',
    imgSrc: sleeves10,
    price: 40,
    refImgsrc: sleeves_ref10
  },
  {
    name: 'Straight (Mid)',
    imgSrc: sleeves11,
    price: 40,
    refImgsrc: sleeves_ref11
  },
  {
    name: 'Tulip (short)',
    imgSrc: sleeves12,
    price: 40,
    refImgsrc: sleeves_ref12
  },
  {
    name: 'Tulip (Half)',
    imgSrc: sleeves13,
    price: 40,
    refImgsrc: sleeves_ref13
  },
  {
    name: 'Billow',
    imgSrc: sleeves14,
    price: 40,
    refImgsrc: sleeves_ref14
  },
  {
    name: 'Puff Cuff',
    imgSrc: sleeves15,
    price: 40,
    refImgsrc: sleeves_ref15
  },
]

export const swatches = [
  {
    name: 'Sabaku Twill White',
    imgSrc: swatch3,
    price: 14
  },
  {
    name: 'Sabaku Twill Green',
    imgSrc: swatch5,
    price: 14
  },
  {
    name: 'Sabaku Twill Grey',
    imgSrc: swatch6,
    price: 14
  },
  {
    name: 'Sabaku Twill Red',
    imgSrc: swatch7,
    price: 14
  },
  {
    name: 'Sabaku Twill Blue',
    imgSrc: swatch8,
    price: 14
  },
  {
    name: 'Sabaku Twill Calcium',
    imgSrc: swatch9,
    price: 14
  },
  {
    name: 'Sabaku Twill Stone',
    imgSrc: swatch10,
    price: 14
  },
  {
    name: 'Sabaku Twill Pewter',
    imgSrc: swatch11,
    price: 14
  },
  {
    name: 'Sabaku Twill Parchment',
    imgSrc: swatch12,
    price: 14
  },
  {
    name: 'Sabaku Twill Pickle',
    imgSrc: swatch13,
    price: 14
  },
  {
    name: 'Sabaku Twill Black',
    imgSrc: swatch14,
    price: 14
  },
  {
    name: 'Flower Bomb',
    imgSrc: swatch15,
    price: 14
  },
  {
    name: 'Flower Bomb Cotton',
    imgSrc: swatch16,
    price: 14
  },
  {
    name: 'Rowan',
    imgSrc: swatch17,
    price: 14
  },
  {
    name: 'World Map',
    imgSrc: swatch18,
    price: 14
  },
  {
    name: 'Shirting Canvas Undyed',
    imgSrc: swatch19,
    price: 14
  },
  {
    name: 'Shirting Canvas Tobacco',
    imgSrc: swatch20,
    price: 14
  },
  {
    name: 'Shirting Canvas Navy',
    imgSrc: swatch21,
    price: 14
  },
  {
    name: 'Shirting Canvas Black',
    imgSrc: swatch22,
    price: 14
  },
  {
    name: 'Japanese Chambray Brown',
    imgSrc: swatch23,
    price: 14
  },
  {
    name: 'Japanese Chambray Blue',
    imgSrc: swatch24,
    price: 14
  },
  {
    name: 'Vinatge Cotton Natural',
    imgSrc: swatch25,
    price: 14
  },
  {
    name: 'Vinatge Cotton Burgundy Check',
    imgSrc: swatch26,
    price: 14
  },
  {
    name: 'Vinatge Cotton Corners',
    imgSrc: swatch27,
    price: 14
  },
  {
    name: 'Vinatge Cotton Plaid',
    imgSrc: swatch28,
    price: 14
  },
  {
    name: 'Vinatge Cotton Equal Check',
    imgSrc: swatch29,
    price: 14
  },
  {
    name: 'Vinatge Cotton Weave Check',
    imgSrc: swatch30,
    price: 14
  },
  {
    name: 'Vinatge Cotton Thread Check',
    imgSrc: swatch31,
    price: 14
  },
  {
    name: 'Vinatge Cotton Green',
    imgSrc: swatch32,
    price: 14
  },
  {
    name: 'Vinatge Cotton Nines',
    imgSrc: swatch33,
    price: 14
  },
  {
    name: 'Cotton Muslin Ochre',
    imgSrc: swatch34,
    price: 14
  },
  {
    name: 'Cotton Muslin Orange',
    imgSrc: swatch35,
    price: 14
  },
  {
    name: 'Cotton Muslin Pistachio',
    imgSrc: swatch36,
    price: 14
  },
  {
    name: 'Cotton Muslin Mauve',
    imgSrc: swatch37,
    price: 14
  },
  {
    name: 'Cotton Muslin Pink',
    imgSrc: swatch38,
    price: 14
  },
  {
    name: 'Cotton Muslin Grey',
    imgSrc: swatch39,
    price: 14
  },
  {
    name: 'Cotton Muslin Navy',
    imgSrc: swatch40,
    price: 14
  },
  {
    name: 'Cotton Lawn White',
    imgSrc: swatch41,
    price: 16
  },
  {
    name: 'Cotton Lawn Sand',
    imgSrc: swatch42,
    price: 16
  },
  {
    name: 'Cotton Lawn Grey',
    imgSrc: swatch43,
    price: 16
  },
  {
    name: 'Cotton Lawn Yellow',
    imgSrc: swatch44,
    price: 16
  },
  {
    name: 'Cotton Lawn Peach',
    imgSrc: swatch45,
    price: 16
  },
  {
    name: 'Cotton Lawn Navy',
    imgSrc: swatch46,
    price: 16
  },
  {
    name: 'Cotton Lawn Black',
    imgSrc: swatch47,
    price: 16
  },
  {
    name: 'Fine Poplin White',
    imgSrc: swatch48,
    price: 16
  },
  {
    name: 'Fine Poplin Sand',
    imgSrc: swatch49,
    price: 16
  },
  {
    name: 'Fine Poplin Ballet',
    imgSrc: swatch50,
    price: 16
  },
  {
    name: 'Fine Poplin Sky',
    imgSrc: swatch51,
    price: 16
  },
  {
    name: 'Fine Poplin Pink',
    imgSrc: swatch52,
    price: 16
  },
  {
    name: 'Fine Poplin Grey',
    imgSrc: swatch53,
    price: 16
  },
  {
    name: 'Fine Poplin Dark Blue',
    imgSrc: swatch54,
    price: 16
  },
  {
    name: 'Fine Poplin Lemon',
    imgSrc: swatch55,
    price: 16
  },
  {
    name: 'Fine Poplin Coral',
    imgSrc: swatch56,
    price: 16
  },
  {
    name: 'Fine Poplin Cobalt',
    imgSrc: swatch57,
    price: 16
  },
  {
    name: 'Fine Poplin Pyramid',
    imgSrc: swatch58,
    price: 16
  },
  {
    name: 'Fine Poplin Black',
    imgSrc: swatch59,
    price: 16
  },
  {
    name: 'Organic Cotton White',
    imgSrc: swatch70,
    price: 18
  },
  {
    name: 'Organic Cotton Cream',
    imgSrc: swatch71,
    price: 18
  },
  {
    name: 'Organic Cotton Black',
    imgSrc: swatch72,
    price: 18
  },
  {
    name: 'Organic Chambray Light',
    imgSrc: swatch73,
    price: 18
  },
  {
    name: 'Organic Chambray Blue',
    imgSrc: swatch74,
    price: 18
  },
  {
    name: 'Organic Denim Red',
    imgSrc: swatch75,
    price: 18
  },
  {
    name: 'Organic Denim Blue',
    imgSrc: swatch76,
    price: 18
  },
  {
    name: 'Dasshu Star',
    imgSrc: swatch77,
    price: 16
  },
  {
    name: 'Nami Red',
    imgSrc: swatch78,
    price: 16
  },
  {
    name: 'Nami Blue',
    imgSrc: swatch79,
    price: 16
  },
  {
    name: 'Cross Print Ecru',
    imgSrc: swatch80,
    price: 16
  },
  {
    name: 'Cross Print Red',
    imgSrc: swatch81,
    price: 16
  },
  {
    name: 'Sirius Print Indigo',
    imgSrc: swatch82,
    price: 21
  },
  {
    name: 'Seigaiha Dot Sky Blue',
    imgSrc: swatch83,
    price: 16
  },
  {
    name: 'Seigaiha Dot Mid Blue',
    imgSrc: swatch84,
    price: 16
  },
  {
    name: 'Hoshi Print',
    imgSrc: swatch85,
    price: 18
  },
  {
    name: 'Small Rabbit Blue',
    imgSrc: swatch86,
    price: 16
  },
  {
    name: 'Small Rabbit Brown',
    imgSrc: swatch87,
    price: 16
  },
  {
    name: 'Small Rabbit Black',
    imgSrc: swatch88,
    price: 16
  },
  {
    name: 'Rabbit and Waves',
    imgSrc: swatch89,
    price: 16
  },
  {
    name: 'Zakuro Stitch Blue',
    imgSrc: swatch90,
    price: 39
  },
  {
    name: 'Zakuro Stitch Black',
    imgSrc: swatch91,
    price: 39
  },
  {
    name: 'Shisa White',
    imgSrc: swatch92,
    price: 26
  },
  {
    name: 'Shisa Grey',
    imgSrc: swatch93,
    price: 26
  },
  {
    name: 'Waves Blue',
    imgSrc: swatch94,
    price: 26
  },
  {
    name: 'Waves Grey',
    imgSrc: swatch95,
    price: 26
  },
  {
    name: 'Cotton Shirting Sky Blue',
    imgSrc: swatch96,
    price: 14
  },
  {
    name: 'Cotton Shirting Navy',
    imgSrc: swatch97,
    price: 14
  },
  {
    name: 'Cotton Shirting Micro Stripe',
    imgSrc: swatch98,
    price: 14
  },
  {
    name: 'Cotton Shirting Red Windmill',
    imgSrc: swatch99,
    price: 14
  },
  {
    name: 'Cotton Shirting Blue Pearls',
    imgSrc: swatch100,
    price: 14
  },
  {
    name: 'Cotton Shirting Brown Clover',
    imgSrc: swatch101,
    price: 14
  },
  {
    name: 'Cotton ShirtingMini Gingham',
    imgSrc: swatch102,
    price: 14
  },
  {
    name: 'Cotton Shirting Diamonds',
    imgSrc: swatch103,
    price: 14
  },
  {
    name: 'Cotton Shirting Multi Gingham',
    imgSrc: swatch104,
    price: 14
  },
  {
    name: 'Cotton Shirting madras Blue',
    imgSrc: swatch105,
    price: 14
  },
  {
    name: 'Indigo Cotton Rose',
    imgSrc: swatch106,
    price: 18
  },
  {
    name: 'Indigo Cotton Star',
    imgSrc: swatch107,
    price: 18
  },
  {
    name: 'Indigo Cotton Polka Dot',
    imgSrc: swatch108,
    price: 18
  },
  {
    name: 'Indigo Cotton Chiru',
    imgSrc: swatch109,
    price: 18
  },
  {
    name: 'Indigo Cotton Shippou',
    imgSrc: swatch110,
    price: 18
  },
  {
    name: 'Japanese Wind White',
    imgSrc: swatch111,
    price: 18
  },
  {
    name: 'Indigo Cotton Wind Navy',
    imgSrc: swatch112,
    price: 18
  },
  {
    name: 'Broderick Anglaise Dots White',
    imgSrc: swatch113,
    price: 18
  },
  {
    name: 'Broderick Anglaise Zag',
    imgSrc: swatch114,
    price: 18
  },
  {
    name: 'Broderick Anglaise Chain Link',
    imgSrc: swatch115,
    price: 18
  },
  {
    name: 'Broderick Anglaise Formation',
    imgSrc: swatch116,
    price: 18
  },
  {
    name: 'Broderick Anglaise Daisy',
    imgSrc: swatch117,
    price: 18
  },
  {
    name: 'Broderick Anglaise Crop',
    imgSrc: swatch118,
    price: 18
  },
  {
    name: 'Pin Tuck Cotton White',
    imgSrc: swatch119,
    price: 18
  },
  {
    name: 'Pin Tuck Cotton Black',
    imgSrc: swatch120,
    price: 18
  },
  {
    name: 'Viscose Sateen',
    imgSrc: swatch121,
    price: 20
  },
  {
    name: 'Viscose Sateen',
    imgSrc: swatch122,
    price: 20
  },
  {
    name: 'Viscose Sateen',
    imgSrc: swatch123,
    price: 20
  },
  {
    name: 'Viscose Sateen',
    imgSrc: swatch124,
    price: 20
  },
  {
    name: 'Viscose Sateen',
    imgSrc: swatch125,
    price: 20
  },
  {
    name: 'Viscose Sateen',
    imgSrc: swatch126,
    price: 20
  },
  {
    name: 'Viscose Damask Print Blue',
    imgSrc: swatch127,
    price: 25
  },
  {
    name: 'Viscose Damask Print Black',
    imgSrc: swatch128,
    price: 25
  },
  {
    name: 'Viscose Flower Print Black',
    imgSrc: swatch129,
    price: 25
  },
  {
    name: 'Viscose Flower Print Green',
    imgSrc: swatch130,
    price: 25
  },
  {
    name: 'Viscose Flower Print Red',
    imgSrc: swatch131,
    price: 25
  },
  {
    name: 'Viscose Seed Print Red',
    imgSrc: swatch132,
    price: 25
  },
  {
    name: 'Viscose Seed Print Black',
    imgSrc: swatch133,
    price: 25
  },
  {
    name: 'Seersucker Cotton White',
    imgSrc: swatch134,
    price: 24
  },
  {
    name: 'Seersucker Cotton Navy',
    imgSrc: swatch135,
    price: 24
  },
  {
    name: 'Seersucker Cotton Brown',
    imgSrc: swatch136,
    price: 24
  },
  {
    name: 'Seersucker Cotton Black',
    imgSrc: swatch137,
    price: 24
  },
]
