import React, { useState, useEffect } from 'react'
import { loginFromTokenRequest } from 'App/auth/actions'
import { connect } from 'react-redux'
import Home from 'pages/Home'
import Login from 'pages/Login'
import About from 'pages/About'
import Admin from 'pages/Admin'
import Create from 'pages/Create'
import Collection from 'pages/Collection'
import Menu from 'components/Menu'
import Profile from 'pages/Profile'
import Remake from 'pages/Remake'
import Stylist from 'pages/Stylist'
import Users from 'pages/Users'
import Header from 'components/Header'
import WithAuth from 'App/auth'
import { Switch, Route } from 'react-router-dom'
import 'style/index.css'

function App({ loginFromTokenRequest }) {
  const [init, setInit] = useState(false)
  useEffect(() => {
    if (!init) {
      loginFromTokenRequest()
      setInit(true)
    }
  })
  return (
    <>
      <Route path='/' children={props => <Header {...{ props }} />} />
      <Menu />
      <Switch>
        <Route exact path='/' children={<Home />} />
        <Route path='/login' children={<Login />} />
        <Route path='/about' children={<About />} />
        <Route path='/collection' children={<Collection />} />
        <Route path='/create/:type' children={props => <Create {...{ props }} />} />
        <Route path='/create' children={props => <Create {...{ props }} />} />
        <Route path='/remake' children={<Remake />} />
        <Route path='/stylist' children={<Stylist />} />
        <Route path='/users' children={<Users />} />
        <Route path='/admin' component={WithAuth(Admin)} />
        <Route path='/profile' component={WithAuth(Profile)} />
        <Route><div>-- no match --</div></Route>
      </Switch>
    </>
  )
}

const connected = connect(null, { loginFromTokenRequest })(App)
export default connected
