import styled from 'styled-components'

export const Header = styled.header`
  position: relative;
  padding: 40px 0;
  h1 {
    text-align: center;
    img {
      width: 45px;
      transform: rotate(270deg);
      margin-right: -10px;
      margin-bottom: -4px;
    }
  }
  > p {
    font-family: lato, sans-serif;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 200;
    position: absolute;
    top: 50px;
    &:last-of-type {
      right: 0;
    }
    
    transition: .25s ease-in-out;
    @media (min-width: 1000px) {
      opacity: 1;
    }
  }
  > div {
    position: absolute;
    left: 72px;
    margin-top: 3px;
  }
  a, span {
    text-decoration: none;
    cursor: pointer;
    color: #333;
    &:hover {
      opacity: 0.75;
    }
  }
`
