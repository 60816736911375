import styled from 'styled-components'

export const Div = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  @media (min-width: 800px) {
    width: 80%;
  }
  > div {
    width: 100%;
  }

  span {
    display: block;
    width: 200px;
    margin: 20px 0;
    svg {
      width: 100%;
    }
  }
`
