import styled from 'styled-components'

export const Main = styled.main`
  width: 100%;
  text-align: center;
  height: calc(100% - 183px);
  @keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  animation: fadeIn ease .5s;
  h3 {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 20px;
  }
`

export const Loading = styled.svg`
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
      stroke-dashoffset: calc(0.66 * 40);
    } 50% {
      transform: rotate(720deg);
      stroke-dashoffset: calc(3.14 * 40);
    } 100% {
      transform: rotate(1080deg);
      stroke-dashoffset: calc(0.66 * 40);
    }
  }
  width: 40px;
  height: 40px;
  x: 0px;
  y: 0px;
  viewBox: 0 0 40 40;
  circle {
    fill: transparent;
    stroke: #212121;
    stroke-width: 4;
    stroke-linecap: round;
    stroke-dasharray: calc(3.14 * 40);
    transform-origin: calc(0.5px * 40) calc(0.5px * 40) 0;
    animation: spinner 2s linear infinite;
  }
`
