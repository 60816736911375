import * as S from './SignUpForm.styles'

function SignUpForm({ name, email, password, updateSignUpForm, signUp, messages, errors }) {
  return (
    <S.Form onSubmit={e => e.preventDefault()}>
      <input
        type='text'
        placeholder='Name'
        value={name}
        onChange={e => updateSignUpForm(e.target.value, 'name')}
      />
      <input
        type='text'
        placeholder='Email'
        value={email}
        onChange={e => updateSignUpForm(e.target.value, 'email')}
      />
      <input
        type='password'
        placeholder='Password'
        value={password}
        onChange={e => updateSignUpForm(e.target.value, 'password')}
      />
      <div>
        <button onClick={signUp}>{'Sign up'}</button>
      </div>
      {errors.length > 0
        ? <p>- {!!errors[0].type.message ? 'Email already registered.' : errors[0].type} -</p>
        : <p>{'Please use a password with at least 8 letters, a capital letter, a number and a symbol, e.g. ! or ?'}</p>
      }
    </S.Form>
  )
}

export default SignUpForm
