import { useEffect, useState } from 'react'
import { FETCHING, SUCCESS, ERROR, useApiRequest } from './actions'
import Add from './add/Add'
import Edit from './edit/Edit'
import loading from 'img/loading.svg'
import * as S from './Create.styles'

const Create = () => {
  const [{ status, response }, getCreate] = useApiRequest(`/api/create`)
  const [editing, setEditing] = useState(false)

  useEffect(() => status === null && (
    getCreate()
  ),[getCreate, status])

  return (
    <>
      {editing && status === SUCCESS &&
        <Edit {...{ editing, setEditing }} />
      }
      <S.Div>
        {status === FETCHING &&
          <p><img src={loading} alt={''} /></p>
        }
        {status === ERROR &&
          <p>{'There was an error fetching the results'}</p>
        }
        {status === SUCCESS &&
          <>
            <ul>
              {response.data.map(({ _id, name, svg, active }, i) =>
                <li key={i}>
                  <h3>{name}</h3>
                  <span dangerouslySetInnerHTML={{ __html: svg }} />
                  <div>
                    <label htmlFor={`active${i}`}>{'Active:'}</label>
                    <input type={'checkbox'} id={`active${i}`} checked={active} onChange={() => null} />
                  </div>
                  <button onClick={() => {
                    document.body.style.overflow = 'hidden'
                    setEditing({ _id, name, svg, active })
                  }}>{'Edit'}</button>
                </li>
              )}
            </ul>
            <Add />
          </>
        }
      </S.Div>
    </>
  )
}

export default Create
